'use strict';

angular.module('controller.site', [
    'Devise',
    'ui.bootstrap',
    'directive.close-button',
    'service.intercom',
    'service.marketing',
    'service.modals',
    'service.patterns',
    'service.manage-user-cookie',
    'service.securityService',
    'directive.secured',
    'service.adaptive-views'
  ])
  .controller('SiteCtrl', [
    '$scope',
    '$cookies',
    '$rootScope',
    '$state',
    '$location',
    '$window',
    'Auth',
    'config',
    'currentUser',
    'storedAccount',
    'intercom',
    'marketing',
    'modals',
    'patterns',
    '$analytics',
    '$timeout',
    'manageUserCookie',
    'securityServiceFactory',
    'adaptiveViews',
    'branding',
    siteController
  ]);

/**
 * Once currentUser is available, make it globally accessible within
 * templates via site scope.
 *
 * @param {Object} $scope
 * @param {Object} currentUser
 */
function siteController($scope, $cookies, $rootScope, $state, $location, $window, Auth, config, currentUser, account, intercom, marketing,
  modals, patterns, $analytics, $timeout, manageUserCookie, securityServiceFactory, adaptiveViews, branding) {
  $rootScope.currentUser = currentUser;
  $rootScope.security = securityServiceFactory.create(currentUser);
  $rootScope.isMobile = adaptiveViews.isMobile();
  $rootScope.isApp = adaptiveViews.isApp();
  $rootScope.branding = branding;

  $scope.footerLinks = {
    privacyPolicy: {
      title: 'Privacy',
      url: 'https://www.worldsourcewealth.com/privacy'
    },
    legal: {
      title: 'Legal',
      url: 'https://www.worldsourcewealth.com/legal'
    },
    complaints: {
      title: 'Complaints',
      url: 'https://www.worldsourcewealth.com/complaints'
    },
    accessibility: {
      title: 'Accessibility',
      url: 'https://www.worldsourcewealth.com/accessibility'
    },
    socialMedia: {
      title: 'Social Media',
      url: 'https://www.worldsourcewealth.com/social-media'
    }
  };

  $scope.currentYear = new Date().getFullYear();

  angular.extend($rootScope, marketing);
  angular.extend($rootScope, modals);

  $rootScope.logout = function(targetUrl) {
    intercom.shutdown();
    Auth.logout()
      .finally(function() {
        $analytics.eventTrack('signed-out');
        $window.location.href = targetUrl || '/signout';
      });
  };

  $rootScope.gotoSignin = function() {
    if (adaptiveViews.isMobile()) {
      return $state.go('account.signin');
    }
    return modals.openSigninModal();
  };

  $rootScope.state = function() {
    var state = $state.$current;
    var states = [];

    while (state) {
      states.push(state.name.replace('.', '-'));
      state = state.parent;
    }

    return states.join(' ');
  };

  $rootScope.contentCssClass = function() {
    var state = $state.$current;
    if (state && state.cssClass) {
      return state.cssClass;
    }
    return '';
  };

  /**
   * Redirect user to interview step 1 if user is not already
   * signed in.
   *
   */
  $rootScope.signup = function() {
    if (!currentUser) {
      if (config.env.registration) {
        window.location.href = '/create_client'
      } else {
        return $rootScope.openMailingListModal({
          modalBody: 'ModernAdvisor is currently available on an invitation-only basis. If you would like to receive an invitation please leave your email address below. We will notify you as soon as new spots become available.',
          modalTitle: 'Thanks for your interest!'
        });
      }
    }
  };

  /**
   * Open the account trial account signup modal dialog if user is not already
   * signed in.
   *
   */
  $rootScope.trialSignup = function() {
    if (!currentUser) {
      if (config.env.registration) {
        return $rootScope.openTrialRegistrationModal();
      }
    }
  };

  /**
   * Used for navigating to the interview, with different options that can be preselected.
   */
  function preselectAccountType(accountTypeName) {
    account.typeId(config.types.Account.findByName(accountTypeName).id);
    account.save();
  }

  $rootScope.gotoInterview = function(type) {
    if (type && type.accountTypeName) {
      preselectAccountType(type.accountTypeName);
    } else {
      switch (type) {
        case 'trial':
          if (!$rootScope.currentUser) {
            // trial can be used only for new clients
            preselectAccountType('trial');
            return $state.go('interview.goal', {
              preselectTrial: true
            });
          }
          break;
        case 'individualRrsp':
          preselectAccountType('individual_rrsp');
          break;
      }
    }
    $state.go('/investment/goal');
  };

  /**
   * Show the exit popup to non-users visiting home routes
   *
   */
  $rootScope.exitPopup = function() {
    if (!currentUser && $state.includes('home')) {
      return $rootScope.openMailingListModal({
        modalBody: 'Subscribe to our newsletter to get market updates and investing tips. You\'ll get your first three months with ModernAdvisor free.',
        modalTitle: 'Knowledge is power'
      });
    }
  };


  $rootScope.showIntercomMessageWindow = function() {
    window.Intercom('show');
  };

  // useful regular expressions
  $rootScope.patterns = patterns;

  $rootScope.setLoadText = function(text) {
    $rootScope.loadText = text;
  };

  $rootScope.hideLoadAnimation = function() {
    $rootScope.showLoadAnimation = false;
  };

  //Banners//
  //Top Banner
  $rootScope.topBannerDisabled = false;
  $rootScope.removeTopBanner = function() {
    $('#responsible-form').addClass('slideup');
    $rootScope.topBannerDisabled = true;
  };

  //Scrolling Banner
  var bannerDisabled = false;
  $rootScope.disableBanner = function() {
    bannerDisabled = true;
    $rootScope.removeBanner();
  };

  $rootScope.validateForm = function(form) {
    $(':focus').blur();

    _.each(form.$error, function(errors) {
      _.each(errors, function(control) {
        control.$setDirty();
      });
    });
    // this is the current scope where the function is executed
    this.$broadcast('submit');

    return form.$valid;
  };

  /**
   * Show banner to non-users visiting specific pages
   *
   */
  var callBanner = function() {
    if (!currentUser) {
      //separated conditionals required to work on android devices
      if (!manageUserCookie.getCookie()) {
        if (_.contains(['home.methodology', 'home.main', 'home.pricing'], $state.current.name) && !bannerDisabled) {
          $(window).scroll(function() {
            return $rootScope.addScrollBanner();
          });
        } else {
          // This is not implicitly done on mobile.  We need to do it here.
          $(window).off('scroll');
        }
      } else {
        return $rootScope.removeBanner();
      }
    }
  };

  $rootScope.$on('$stateChangeSuccess', callBanner);
  callBanner();

  var scrollUp = function() {
    if (!$state.includes('etfs')) {
      window.scrollTo(0, 0);
    }
  };

  $rootScope.$on('$locationChangeStart', scrollUp);
  $rootScope.$on('$locationChangeStart', (_event, newUrl, oldUrl) => {
    $location.state({ newUrl: newUrl, oldUrl: oldUrl });
  });

  $rootScope.showProBanner = function() {
    return $state.current.name === 'home.main' &&
      !adaptiveViews.isMobile() &&
      !$cookies.get('dismissed_pro_alert') &&
      !$cookies.get('group_url_id') &&
      !manageUserCookie.getCookie();
  };

  $rootScope.showPromoBanner = function() {
    return $state.current.name === 'home.main' &&
      !adaptiveViews.isMobile() &&
      !$cookies.get('dismissed_promo_alert') &&
      !$cookies.get('group_url_id') &&
      !manageUserCookie.getCookie();
  };

  $rootScope.isPersonalClientDashboardOnDesktop = function() {
    return $rootScope.security.isPersonalClient() &&
      ($state.includes('dashboard') ||
        $state.includes('profile') ||
        $state.includes('kyc') ||
        $state.includes('kyc-completed')) &&
      !adaptiveViews.isMobile();
  };
}
