'use strict';

angular.module('routes.account', [
    'controller.account.signin',
    'controller.account.registration',
    'controller.account.confirm-email',
    'controller.minimal-footer',
    'templates',
    'ui.router',
    'service.account-service',
    'service.navigation',
    'ngCookies',
    'model.KycUpdate',
    'service.adaptive-views'
  ])
  .config([
    '$stateProvider',
    'adaptiveViewsProvider',
    configRoutes
  ]);

/**
 * Initialize angular UI router for account management.
 *
 * @param  {Object} $stateProvider API used to declare application states.
 */
function configRoutes($stateProvider, adaptiveViewsProvider) {

  function resolveAccounts(currentUser) {
    return currentUser && currentUser.accounts.ready();
  }

  function resolveKycUpdate(currentUser, KycUpdate) {
    return currentUser && KycUpdate.find({
      userId: currentUser.id,
      completed: false
    });
  }

  function openTrialRegistrationModal(modals, redirect, storedUser, storedGoal) {
    if (storedGoal.initial() === undefined || storedUser.riskAppetite() === undefined) {
      return redirect.to('interview.goal').always();
    }
    modals.openTrialRegistrationModal();
    redirect.to('home.main').always();
  }

  function route(navigationService, currentUser, accounts, kycUpdate) {
    navigationService.onUserSignedIn(currentUser, accounts, kycUpdate);
  }

  $stateProvider
    .state('account', {
      parent: 'site',
      abstract: true,
      resolve: {
        kycUpdate: ['currentUser', 'KycUpdate', resolveKycUpdate],
        accounts: ['currentUser', resolveAccounts],
        accountService: 'accountService'
      },
      views: {
        'main': {},
        'navbar': {
          templateUrl: 'navbar/preview.html'
        },
        'footer': {
          controller: 'minimalFooterCtrl',
          templateUrl: adaptiveViewsProvider.isMobile() ? 'mobile/partials/footer.html' : 'partials/footer-minimal.html'
        }
      }
    })
    .state('account.signin', {
      url: '/signin?emailConfirmed&error&advisorNewAccount&sessionExpired',
      params: {
        previousSuccessAction: undefined
      },
      resolve: {
        route: ['navigationService', 'currentUser', 'accounts', 'kycUpdate', route]
      },
      views: {
        'main@site': {
          controller: 'AccountSigninCtrl'
        },
        'navbar@site': {
          templateUrl: 'navbar/minimal.html'
        },
        'footer@site': {
          templateUrl: 'partials/footer-minimal.html'
        }
      },
      //cssClass: 'no-mobile-footer',
      hasMobileView: false,
      data: {
        tracking: {
          pageTitle: 'Login',
          pageType: 'session',
          pageId: 'login',
          contents: {
            content: [{
              type: 'form',
            }],
            category: { primary: "session" }
          },
          forms: [{
            id: "login",
            name: "Login",
            type: "login"
          }]
        }
      }
    })
    .state('account.signout', {})
    .state('account.confirm', {})
    .state('account.register', { // Regular account
      url: '/register',
      resolve: {
        redirect: ['redirect', function(redirect) {
          redirect.to('interview.goal').always();
        }]
      },
      parent: 'site',
      template: ''
    })
    .state('account.registration', { // Trial account
      url: '/signup',
      resolve: {
        modal: ['modals', 'redirect', 'storedUser', 'storedGoal', openTrialRegistrationModal],
        route: ['navigationService', 'currentUser', 'accounts', 'kycUpdate', route]
      }
    })
    .state('account.confirm-email', {
      url: '/confirm-email?emailConfirmed&error',
      hasMobileView: true,
      controller: 'AccountConfirmEmailCtrl'
    });

}
